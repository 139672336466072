@import 'shared/styles/vars'
    
.loader
    .logoWrapper
        display: flex
        align-items: center
        justify-content: center
        gap: 7px
        position: absolute
        z-index: 10
        width: 100%
        height: 100%
        pointer-events: none
        @media(max-width: $tab)
            width: 90%
            transform: translateY(calc(-60px - 2vw - 1%))
        svg
            @media(max-width: $tab)
                width: 100%
    .backDrop
        position: absolute
        width: 100%
        height: 100%
        transition: all var(--trs-300)
        z-index: 5
        pointer-events: none