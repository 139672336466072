@import 'shared/styles/vars'
    
.modalWrapper.open
    pointer-events: all
    .bg
        transform: translateY(0)
        opacity: 1
    .contentWrapper
        transform: translateY(0)
        opacity: 1
        transition-delay: 500ms
    
.modalWrapper
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    display: flex
    align-items: center
    justify-content: center
    z-index: 110
    pointer-events: none
    .bg
        opacity: 0
        transition: opacity var(--trs-500), transform var(--trs-700)
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: var(--surface-secondary-light-color)
        transform: translateY(100%)
    .contentWrapper
        transform: translateY(100%)
        transition: opacity var(--trs-700), transform var(--trs-700)
        transition-delay: 0ms
        position: absolute
        background: var(--surface-secondary-color)
        top: 0
        left: 0
        right: 0
        bottom: 0
        opacity: 0
        display: grid
        grid-template-columns: 49% 49%
        padding: 48px
        gap: 1%
        @media(max-width: $tab)
            padding: 1rem
            grid-template-columns: 100%
            grid-template-rows: 25% 63%
        .closeBtn
            position: absolute
            top: 48px
            right: 48px
            z-index: 10
            @media(max-width: $tab)
                top: 1rem
                right: 1rem
        .content
            z-index: 2
            display: grid
            grid-template-rows: repeat(2,1fr)
            height: 100%
                
            .top
                position: relative
                @media(max-width: $tab)
                    margin-right: 4rem
                .swiper
                    position: absolute
                    width: 100%
                    
                    flex: 1
                    height: 100%
                    @media(max-width: $tab)
                        height: auto
                    .swiperItem
                        display: flex
                        flex-direction: column
                        justify-content: space-between
                        .title
                            color: var(--text-primary-color)
                            font-family: Cera Pro, sans-serif
                            font-size: 40px
                            font-style: normal
                            font-weight: 400
                            line-height: 100%
                            letter-spacing: -2px 
                            @media(max-width: $tab)
                                font-size: 24px
                        .textWrapper
                            margin-top: 1rem
                            .description
                                color: var(--text-primary-color)
                                font-family: Cera Pro, sans-serif
                                font-size: 16px
                                font-style: normal
                                font-weight: 400
                                line-height: 100%
                                letter-spacing: -0.8px 
                                &:first-child
                                    padding-bottom: 1rem
                                    border-bottom: 1px solid rgba(220, 197, 183, 0.20)
                                @media(max-width: $tab)
                                    font-size: 14px
                                    margin: 0
                                    &:first-child
                                        padding-bottom: .4rem
                                    &:last-child
                                        padding-top: .4rem
            .bottom
                display: flex
                align-items: flex-end
                justify-content: space-between
                position: relative
                @media(max-width: $tab)
                    position: absolute
                    bottom: 1rem
                    right: 1rem
                    left: 1rem
                    border-top: 0
                .navigation
                    display: flex
                    gap: 8px
                .count
                    font-family: Cera Pro, sans-serif
                    font-size: 128px
                    font-style: normal
                    font-weight: 400
                    line-height: 80%
                    letter-spacing: -6.4px 
                    color: var(--text-primary-color)
                    @media(max-width: $tab)
                        font-size: 82px
        .swiperWrapper
            flex: 1
            display: flex
            align-items: center
            justify-content: center
            height: 100%
            width: 100%
            position: relative
            padding-right: 77px
            box-sizing: border-box
            padding-bottom: 1rem
            @media(max-width: $tab)
                padding-right: 0
                padding-bottom: 1rem
                padding-top: 2rem
            .swiper
                width: 100%
                height: 100%
                padding: 0 1rem
                box-sizing: border-box
                overflow: hidden
                border-right: 1px solid rgba(220, 197, 183, 0.20)
                border-left: 1px solid rgba(220, 197, 183, 0.20)
                @media(max-width: $tab)
                    border-right: 0
                    border-left: 0
                    padding: 0
            img
                height: 85vh
                width: 100%
                object-fit: cover
                @media(max-width: $tab)
                    height: 100%
            .btn
                position: absolute
                top: 0
                bottom: 0
                left: 0
                right: 0
                margin: auto
                height: 55px
                width: 55px
                padding: 0
            .pagination
                margin-right: 77px
                width: 91%
                @media(max-width: $tab)
                    width: 100%
                    margin-right: 0