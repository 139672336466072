@import 'shared/styles/vars'
    
.section
    height: 100%
    background: var(--surface-primary-color)
    width: 100%
    display: flex
    flex-direction: column
    @media(max-width: $tab)
        height: 90%
    .top
        padding-top: 8.75rem
        padding-bottom: 3rem
        overflow: hidden
        @media(max-width: $tab)
            padding-top: 1.5rem
        .animRow
            display: flex
            align-items: center
            gap: 4rem
            animation: scroll 25s linear infinite
            @media(max-width: $tab)
                animation-duration: 10s
            span
                font-family: Cera Pro,sans-serif
                font-size: 204px
                font-style: normal
                font-weight: 400
                line-height: 70%
                letter-spacing: -13.26px
                text-transform: uppercase 
                color: var(--text-primary-color)
                @media(max-width: $tab)
                    font-size: 128px
    .content
        flex: 1
        padding: 3rem
        border-top: 1px solid rgba(220, 197, 183, 0.20)
        display: grid
        grid-template-columns: 50% 50%
        @media(max-width: $tab)
            grid-template-rows: 50% 50%
            grid-template-columns: 100%
            padding: 1rem
        .left
            padding-right: 1.5rem
            display: flex
            flex-direction: column
            justify-content: space-between
            border-right: 1px solid rgba(220, 197, 183, 0.20)
            @media(max-width: $tab)
                border-right: none
                padding-bottom: 1.5rem
                flex-direction: column-reverse
            .info
                display: flex
                flex-direction: column
                gap: 1.5rem
                a
                    font-family: Cera Pro,sans-serif
                    font-size: 48px
                    font-style: normal
                    font-weight: 400
                    line-height: 70%
                    text-transform: uppercase 
                    color: var(--text-primary-color)
                    @media(max-width: $tab)
                        font-size: 28px
                .workMode
                    font-family: Cera Pro,sans-serif
                    font-size: 48px
                    font-style: normal
                    font-weight: 700
                    line-height: 100%
                    color: var(--text-primary-color)
                    @media(max-width: $tab)
                        font-size: 32px
            .address
                display: flex
                gap: .5rem
               
                .label
                    opacity: .5
                span
                    font-family: Cera Pro,sans-serif
                    font-size: 16px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    letter-spacing: -0.8px
                    color: var(--text-primary-color)
        .right
            padding-left: 1.5rem
            display: flex
            flex-direction: column
            justify-content: space-between
            position: relative
            @media(max-width: $tab)
                border-top: 1px solid rgba(220, 197, 183, 0.20)
                padding-top: 1.5rem
                padding-left: 0
            .top
                padding: 0
                display: flex
                justify-content: space-between
                .social
                    display: flex
                    align-items: center
                    gap: .5rem
                    a
                        svg
                            width: 22px
                            height: 22px
                .toTop
                    @media(max-width: $tab)
                        position: absolute
                        bottom: 0
                        left: 0
            .bottom
                display: flex
                justify-content: flex-end
                width: 100%
                .copyright
                    color: var(--text-primary-color)
                    font-family: Cera Pro, sans-serif
                    font-size: 16px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    letter-spacing: -0.8px 

@keyframes scroll 
    from 
        transform: translateX(0)
    to 
        transform: translateX(calc(-100% - 20px))
    