@import 'shared/styles/vars'
    
.section.active
    .leftPart
        p
            .wordWrapper
                .word
                    transform: translateY(0)
                    transition-delay: 0
.section
    display: grid
    grid-template-columns: repeat(2,1fr)
    height: 100%
    position: relative
    @media(max-width: $tab)
        grid-template-columns: repeat(1,1fr)
        grid-auto-flow: dense
        height: 100vh
   
    .btnWrapper
        position: absolute
        bottom: var(--container)
        display: flex
        justify-content: center
        left: 0
        right: 0
        pointer-events: none
        @media(max-width: $tab)
            display: none
        button
            pointer-events: all
    .backDrop
        position: absolute
        width: 100%
        height: 100%
        transition: all var(--trs-300)
        z-index: 5
        pointer-events: none
    .leftPart.disabled
        pointer-events: none
    .leftPart
        background: var(--surface-primary-color)
        @media(max-width: $tab)
            order: 1
            display: none
        .imgWrapper.active
            clip-path: ellipse(100% 100% at 50% 55vh) !important
            @media(max-width: $tab)
                clip-path: ellipse(10vh 20vh at 50% 50%)
        .imgWrapper.isInView
            clip-path: ellipse(20vh 30vh at 50% 50vh)
            @media(max-width: $tab)
                clip-path: ellipse(12vh 20vh at 50% 50%)
        .imgWrapper
            background: var(--surface-primary-color)
            height: 100%
            width: 100%
            object-fit: cover
            // background-image: url('../../images/main-1.jpg')
            // background-repeat: no-repeat
            // background-size: cover
            clip-path: ellipse(2% 5% at 50% 50vh)
            object-position: center
            transition:  var(--trs-700) !important
            @media(max-width: $tab)
                clip-path: ellipse(12vh 20vh at 50% 50%)
        p
            left: var(--container)
            position: absolute
            bottom: 3.5rem
            max-width: 320px
            display: flex
            justify-content: flex-start
            flex-wrap: wrap
            @media(max-width: $tab)
                left: 1rem
                bottom: 1rem
            .wordWrapper
                overflow: hidden
                padding: 0 5px 0
                position: relative
                .word
                    transform: translateY(120%)
                    display: inline-block
                    transition: var(--trs-700) 2600ms
                    font-family: Cera Pro
                    font-size: 16px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    color: var(--text-primary-color)
                    @media(max-width: $tab)
                        transform: translateY(0)
    .rightPart.disabled
        pointer-events: none
    .rightPart
        position: relative
        background: var(--surface-secondary-color)
        @media(max-width: $tab)
            order: -1
        .imgWrapper.active
            clip-path: ellipse(100% 100% at 50% 50vh)
           
        .imgWrapper.isInView
            transform: translateY(0)
            // @media(max-width: $tab)
            //     clip-path: ellipse(12vh 20vh at 50% 50%)
            @media(max-width: $tab)
                clip-path: ellipse(100% 100% at 50% 50vh)
        .imgWrapper
            background: var(--surface-secondary-color)
            height: 100%
            width: 100%
            // background-image: url('../../images/main-2.jpg')
            // background-repeat: no-repeat
            // background-size: cover
            transform-origin: center
            clip-path: ellipse(20vh 30vh at 50% 50vh)
            transition: var(--trs-700) !important
            transform: translateY(-50%)
            object-fit: cover
            @media(max-width: $tab)
                background-size: cover
                background-position: center
                transform: translateY(0)
                clip-path: ellipse(12vh 20vh at 50% 50vh)


      
