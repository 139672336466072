.main
    height: 100vh
    background: #000
    position: relative
    .backDrop
        position: absolute
        width: 100%
        height: 100%
        transition: all 300ms ease
        z-index: 5
        pointer-events: none