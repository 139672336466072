.svg
    .dark
        fill: var(--surface-primary-color)
        transition: var(--trs-1000)

        
.svg
    .light
        fill: var(--surface-quaternary-color)
        transition: var(--trs-1000)
        