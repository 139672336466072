html,
body,
#root,
.app 
    position: relative
    height: 100%


html 
    scroll-behavior: smooth


body 
    margin: 0
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale

  
code 
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace

  

// scrollbar
::-webkit-scrollbar 
    width: 8px
    height: 8px
    background-color: transparent

    &-track 
        background-color: transparent
        border-radius: 5px
    

    &-thumb 
        background-color: #a6b2c3
        border-radius: 5px
    

    &-corner 
        background-color: #ffffff
    

h1,h2,h3,h4,h5,h6
    margin: 0
ul
    list-style: none
    margin: 0
    padding: 0
a
    text-decoration: none
    color: #000