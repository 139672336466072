@import 'shared/styles/vars'
    
.section.active
   .left
        .bottom
            .text
                span
                    transform: translateY(0)
.section
    background: var(--text-primary-color)
    height: 100%
    z-index: 1
    position: relative
    display: grid
    grid-template-columns: 35% 30% 35%
    @media(max-width: 1450px)
       grid-template-columns: 35% 30% 35% 
    @media(max-width: $tab)
        display: flex
        flex-direction: column-reverse
        height: fit-content
        overflow: hidden
    .left
        height: 100%
        display: grid
        grid-template-rows: 60% 40% 
        padding: 1.5rem
        box-sizing: border-box
        @media(max-width: $tab)
            padding: 1rem
        .top
            @media(max-width: $tab)
                display: none
        .bottom
            padding-top: 1.5rem
            border-top: 1px solid rgba(64, 32, 32, 0.20)
            display: flex
            flex-direction: column
            justify-content: space-between
            @media(max-width: $tab)
                border-top: none
            .text
                overflow: hidden
                position: relative
                span
                    transform: translateY(100%)
                    display: inline-block
                    color: var(--text-secondary-color)
                    font-family: Cera Pro, sans-serif
                    font-size: 96px
                    font-style: normal
                    font-weight: 400
                    line-height: 70%
                    letter-spacing: -6.24px
                    text-transform: uppercase 
                    transition: all var(--trs-1200) 300ms
                @media(max-width: $tab)
                    display: none
                    span
                        transform: translateY(0)
            .navigation
                display: flex
                gap: 8px
    .center
        @media(max-width: $tab)
            padding: 1rem
        .mainSwiperWrapper
            display: flex
            align-items: center
            // height: 100%
            height: 100vh
            position: relative
            top: 10px
            pointer-events: none
            @media(max-width: $tab)
                top: 0
                height: 100%
            @media(max-width: $tab-sm)
                aspect-ratio: 1
            .swiperWrapper
                max-width: 400px
                margin: auto
                position: relative
                padding-bottom: 24px
                width: 100%
                height: 100%
                display: flex
                justify-content: center
                align-items: center
                flex-direction: column
                @media(max-width: $tab)
                    border-right: none
                    border-left: none
                    padding: 0
                    padding-bottom: 1rem
                    max-width: 100%
                .swiper
                    max-height: 70vh
                    height: 100%
                    width: 100%
                    border-right: 1px solid  rgba(64, 32, 32, 0.20)
                    border-left: 1px solid  rgba(64, 32, 32, 0.20)
                    @media(max-width: $tab)
                        border: none
                    .slide
                        position: relative
                        aspect-ratio: 1
                        box-sizing: border-box
                        img
                            top: 0
                            left: 0
                            bottom: 0
                            right: 0
                            width: 100%
                            height: 100%
                            position: absolute
                            object-fit: contain
                            padding: 0 0.2rem
                            box-sizing: border-box
                            @media(max-width: $tab)
                                object-fit: cover
                .pagination
                    position: absolute
                    width: 100%
                    bottom: 120px !important
                    top: auto !important
                    @media(max-width: $tab)
                        bottom: 0 !important
    .right
        height: 100%
        display: grid
        // grid-template-rows: 60% 40% 
        grid-template-rows: 85% 15% 
        padding: 1.5rem
        box-sizing: border-box
        @media(max-width: $tab)
            display: flex
            justify-content: flex-end
            flex-direction: column
            padding: 1rem
        .textSwiperWrapper
            width: 100%
            height: 77vh
            box-sizing: border-box
            padding-top: 12vh
            padding-bottom: 1rem
            pointer-events: none
            @media(max-width: $tab)
                padding-top: 0
                padding-bottom: 0
                height: 100%

            @media(max-height: 800px)
                display: flex
                align-items: center

            .swiper
                left: 0
                height: 100%
                width: 95%
                @media(max-width: $tab)
                    // max-height: 490px
                    width: 100%
                    height: fit-content
                .slide
                    // aspect-ratio: 
                    display: flex
                    flex-direction: column
                    gap: 2rem
                    justify-content: space-between
                    height: auto
                    @media(max-width: $tab)
                        gap: 2rem
                        justify-content: flex-start
                    span
                        font-family: Cera Pro, sans-serif
                        color: var(--text-secondary-color)
                    .top
                        display: flex
                        flex-direction: column
                        gap: 1rem
                        .name
                            font-size: 48px
                            font-style: normal
                            font-weight: 400
                            line-height: 100%
                            letter-spacing: -2.4px 
                            text-transform: uppercase
                        .time
                            font-size: 24px
                            font-style: normal
                            font-weight: 400
                            line-height: 100%
                            letter-spacing: -1.44px
                            text-transform: uppercase 
                    .center
                        display: flex
                        flex-direction: column
                        gap: .5rem
                        padding: 0
                        p
                            margin: 0
                            color: #402020
                            font-family: Cera Pro, sans-serif
                            font-size: 16px
                            font-style: normal
                            font-weight: 400
                            line-height: 100%
                            letter-spacing: -0.8px
                        .btn 
                            display: none
                            justify-content: center
                            height: 54px
                            padding: 0
                            width: 100%
                            margin-top: 2rem
                            pointer-events: all
                            @media(max-width: $tab)
                                display: flex
                            .btn
                                width: 100%
                    .bottom
                        display: flex
                        flex-direction: column
                        pointer-events: all
                        @media(max-width: $tab)
                            display: none
                        .btn 
                            display: flex
                            justify-content: center
                            height: 54px
                            padding: 0
                            width: 100%
                            pointer-events: all
                            .btn
                                width: 100%
                button
                    width: 100%
                    display: flex
                    justify-content: center
                        
        .text
            border-top: 1px solid rgba(64, 32, 32, 0.20)
            display: flex
            flex-direction: column
            justify-content: flex-end
            gap: .5rem
            @media(max-width: $tab)
                padding: 1rem 0
                display: none
            .row
                display: flex
                gap: .5rem
                .label
                    white-space: nowrap
                    color: var(--text-secondary-color)
                    font-family: Cera Pro, sans-serif
                    font-size: 16px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    letter-spacing: -0.8px 
                    opacity: .5
                .value
                    white-space: nowrap
                    color: var(--text-secondary-color)
                    font-family: Cera Pro, sans-serif
                    font-size: 16px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    letter-spacing: -0.8px 