@import 'shared/styles/vars'
    
.section.active
    .barmanWrapper
        img
            transform: scale(1)
            transition-delay: 200ms
  
.section
    height: 100%
    overflow: hidden
    display: flex
    align-items: center
    @media(max-width: $tab)
        height: 80vh
        background: var(--surface-secondary-color)
    .barmanWrapper
        display: flex
        align-items: center
        justify-content: center
        height: 40vw
        top: 48px
        background-image: url('../../images/barmanBg.jpg')
        background-color: #AD7F67
        background-repeat: no-repeat
        background-size: cover
        width: calc(65vw - var(--container))
        margin-left: var(--container)
        position: relative
        @media(max-width: $tab)
            top: 0
            width: 100%
            height: 80vh
            margin-left: 0
        img
            transition: transform var(--trs-700)
            transform: scale(.5)
            height: 80%
            @media(max-width: $tab)
                height: 100%
        .quotsWrapper
            position: absolute
            top: var(--container)
            left: var(--container)
        .barmanName
            position: absolute
            bottom: var(--container)
            left: var(--container)
            display: flex
            flex-direction: column
            gap: 4px
            @media(max-width: $tab)
                bottom: 16px
                left: 16px    
            h4
                color: var(--text-secondary-color)
                font-family: Cera Pro
                font-size: 24px
                font-style: normal
                font-weight: 400
                line-height: 100%
                letter-spacing: -1.56px 
            h6
                color: var(--text-primary-color)
                font-family: Cera Pro
                font-size: 14px
                font-style: normal
                font-weight: 400
                line-height: 100% 
                letter-spacing: -0.7px 