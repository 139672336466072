.fade-swiper
    .swiper-slide
        transition: var(--trs-300)
    .swiper-slide-active
        opacity: 1
    .swiper-slide-prev
        opacity: 0
    .swiper-slide-next
        opacity: 0
.pagination.pagination-secondary
    .pagination-bullet
        background: rgba(220, 197, 183, 0.20)
    .pagination-bullet-active
        &::before
            background: var(--surface-quaternary-color)
.pagination
    position: absolute
    bottom: 0 !important
    left: 0
    right: 0
    margin: auto
    display: flex
    justify-content: center
    gap: .3rem
    padding: 0 24px
    box-sizing: border-box
    .pagination-bullet
        height: 1px
        width: 100% 
        background: rgba(64, 32, 32, 0.20)
        cursor: pointer
    .pagination-bullet-active
        &::before
            content: ''
            width: 0
            background: var(--surface-primary-color)
            height: 1px
            display: block
            animation: slidein 4600ms linear 0ms infinite

.swiperPhotoText
    .swiper-slide
        opacity: 0 !important
    .swiper-slide-active
        opacity: 1 !important

@keyframes slidein 
  from 
    width: 0
  to 
    width: 100%