.section
    background: var(--surface-secondary-color)
    height: fit-content
    position: relative
    overflow: hidden
    display: flex
    flex-direction: column
    .content
        margin-top: 1.5rem
        padding-bottom: 1.5rem
        position: relative
        &:after
            bottom: 0
            content: ''
            position: absolute
            width: 100%
            height: 1px
            background: var(--soft-gray-color)
        h5
            display: flex
            flex-direction: column
            font-family: Cera Pro
            font-size: 36px
            font-style: normal
            font-weight: 400
            line-height: 100%
            letter-spacing: -1.8px
            color: var(--text-primary-color)
            margin-bottom: 2rem
        p
            color: var(--text-primary-color)
            font-family: Cera Pro
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: 100% 