@import 'shared/styles/vars'
    
.heroLogo
    display: flex
    align-items: center
    justify-content: center
    gap: 7px
    position: absolute
    z-index: 10
    width: 100%
    height: 100%
    pointer-events: none
    box-sizing: border-box
    // @media(max-width: $tab)
    //     transform: translateY(calc(-60px - 2vw - 1%))
    svg
        @media(max-width: $tab)
            width: 100%
        
