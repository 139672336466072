@import 'shared/styles/vars'
    
.section.active
    .btnWrapper
        opacity: 1
    .wordWrapper
        
        &::before
            transition-delay: 500ms
            opacity: 1
        .word
            transform: translateY(0)
            transition-delay: 300ms
.section.small
    .lineLeft
        width: 28vw
    .lineRight
        width: 28vw
    .imageWrapper
        width: 30vw 
        height: 40vw
        top: 48px
        &::after
            height: 100%
        &::before
            height: 100%
        img
            transform: scale(1)
            position: relative
        // max-height: 860px
        // max-width: 540px
    .wordWrapper
        bottom: 240px
        .word
            transform: translateY(0)
            @media(max-width: $tab)

.section
    background: var(--surface-secondary-color)
    height: 100%
    width: 100%
    position: relative
    overflow: hidden
    display: flex
    align-items: center
    justify-content: center
    @media(max-width: $tab)
        height: 50vh
    .btnWrapper
        position: absolute
        bottom: var(--container)
        right: var(--container)
        display: flex
        justify-content: center
        transition: opacity var(--trs-500)
        opacity: 0
    .lineLeft
        width: 0
        height: 1px
        background: var(--soft-gray-color)
        position: absolute
        left: var(--container)
        transition: width var(--trs-500)
        @media(max-width: $tab)
            display: none
    .lineRight
        width: 0
        height: 1px
        background: var(--soft-gray-color)
        position: absolute
        right: var(--container)
        transition: width var(--trs-1000)
        @media(max-width: $tab)
            display: none
    .imageWrapper
        width: 100%
        height: 100%
        position: relative
        top: 0
        transition: all var(--trs-1000)
        &::after
            content: ''
            position: absolute
            height: 0
            width: 1px
            background: var(--soft-gray-color)
            right: -1.5rem
            transition: var(--trs-1000) 500ms
        &::before
            content: ''
            position: absolute
            height: 0
            width: 1px
            background: var(--soft-gray-color)
            left: -1.5rem
            transition: var(--trs-1000) 500ms
        img.active
            transform: scale(1)
        img
            transition: all var(--trs-1000)
            transform: scale(1.2)
            width: 100%
            height: 100%
            position: absolute
            object-fit: cover
        
    .wordWrapper
        pointer-events: none
        position: absolute
        z-index: 2
        bottom: var(--container)
        left: var(--container)
        right: var(--container)
        overflow: hidden
        display: inline-block
        transition: bottom  var(--trs-1000)
        padding-top: var(--container)
        @media(max-width: $tab)
            left: 0
            right: 0
            bottom: 1rem
            padding: 1rem 1rem 0
            
        &::before
            content: ''
            position: absolute
            width: 100%
            opacity: 0
            height: 1px
            top: 0
            transition: var(--trs-1000) 
            box-sizing: border-box
            background:  var(--soft-gray-color)
            @media(max-width: $tab)
                opacity: 1
        .word
            color: var(--text-primary-color)
            font-family: Cera Pro
            font-size: 128px
            font-style: normal
            font-weight: 400
            line-height: 70%
            display: inline-block
            letter-spacing: -8.32px
            text-transform: uppercase
            transform: translateY(100%)
            transition: transform var(--trs-1000) 
            @media(max-width: $tab)
                transform: translateY(0)
                text-align: center
                font-size: 62px


        