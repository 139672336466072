@import 'shared/styles/vars'
    
.modalWrapper.open
    pointer-events: all
    .bg
        transform: translateY(0)
        opacity: 1
    .contentWrapper
        transform: translateY(0)
        opacity: 1
        transition-delay: 500ms
    
.modalWrapper
    position: fixed
    top: 0
    bottom: 0
    left: 0
    right: 0
    display: flex
    align-items: center
    justify-content: center
    z-index: 9
    pointer-events: none
    .toTop.active 
        opacity: 1
        pointer-events: all
    .toTop 
        position: fixed
        bottom: 50px
        right: 50px
        width: 54px
        height: 54px
        z-index: 3
        opacity: 0
        pointer-events: none
        transition: var(--trs-300)
        &:hover 
            opacity: 1 !important
            background: #402020
            svg 
                path 
                    fill: #DCC5B7
        svg 
            transform: rotate(90deg)

    .bg
        opacity: 0
        transition: opacity var(--trs-500), transform var(--trs-700)
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: var(--surface-quaternary-light-color)
        transform: translateY(100%)
    .contentWrapper
        transform: translateY(100%)
        transition: opacity var(--trs-700), transform var(--trs-700)
        transition-delay: 0ms
        position: absolute
        background: var(--surface-quaternary-color)
        top: 0
        left: 0
        right: 0
        bottom: 0
        opacity: 0
        display: flex
        padding: 48px
        gap: 1%
        overflow: auto
        @media(max-width: $tab)
            padding: 1rem
           
        .content
            z-index: 2
            width: 100%
            margin-top: 130px
            @media(max-width: $tab)
                margin-top: 70px
            .top
                position: relative
                margin-bottom: 30px
                display: flex
                align-items: center
                gap: 20px
                .backBtn 
                    width: 54px
                    height: 54px
                    max-width: 54px
                    max-height: 54px
                    min-width: 54px
                    min-height: 54px
                    @media(max-width: $tab)
                        width: 46px
                        height: 46px
                        max-width: 46px
                        max-height: 46px
                        min-width: 46px
                        min-height: 46px
                .info
                    span
                        color: var(--text-secondary-color)
                        font-family: Cera Pro
                        font-size: 24px
                        font-style: normal
                        font-weight: 400
                        line-height: 100%
                        letter-spacing: -1.2px 
                        display: block
                        margin-bottom: 5px
                        @media(max-width: $tab)
                         
                            font-size: 14px
                            font-style: normal
                            font-weight: 400
                            line-height: 100%
                            letter-spacing: -0.7px
                    p
                        font-family: Cera Pro
                        font-size: 48px
                        font-style: normal
                        font-weight: 400
                        line-height: 100%
                        letter-spacing: -2.4px
                        color: var(--text-secondary-color)
                        margin: 0
                        @media(max-width: $tab) 
                            font-size: 36px
                            font-style: normal
                            font-weight: 400
                            line-height: 100%
                            letter-spacing: -1.8px

            .photoList
               display: grid
               grid-template-columns: repeat(3, 1fr)
               gap: 20px
               margin-bottom: 100px
               @media(max-width: $tab)
                    grid-template-columns: repeat(2, 1fr)
                    margin-bottom: 80px
               @media(max-width: $tab-sm)
                    grid-template-columns: repeat(1, 1fr)
                    margin-bottom: 60px
               .imageWrapper
                    width: 100% 
                    height: 100%
                    aspect-ratio: 1
                    cursor: pointer
                    img 
                        width: 100%
                        height: 100%
                        object-fit: cover