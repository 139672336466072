@import 'shared/styles/vars'
    
.sectionFourText.active
    .wordWrapper
        .word
            transform: translateY(0)
            transition-delay: 300ms
.sectionFourText
    position: absolute
    color: var(--white-color)
    max-width: 610px
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-items: center
    text-align: center
    font-family: Cera Pro
    font-size: 24px
    font-style: normal
    font-weight: 400
    line-height: 100% 
    letter-spacing: -1.44px
    text-transform: uppercase 
    @media(max-width: $tab)
        font-size: 20px
        justify-content: flex-start
        bottom: 5.2rem
        left: 16px
    .wordWrapper
        height: 24px
        overflow: hidden
        padding: 5px 5px 0
        position: relative
        @media(max-width: $tab)
            padding: 0 5px 0
        .word
            transform: translateY(100%)
            display: inline-block
            transition: var(--trs-700) 0ms
            @media(max-width: $tab)
                transform: translateY(0)
