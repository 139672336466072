@import 'shared/styles/vars'

.sectionThreeText.active
    .wordWrapper
        .word
            opacity: 1
            transform: translateY(0)
.sectionThreeText
    right: var(--container)
    bottom: var(--container)
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
    .wordWrapper
        overflow: hidden
        position: relative
        padding: 0 5px 0
        .word
            color: var(--text-secondary-color)
            font-family: Cera Pro
            font-size: 16px
            font-style: normal
            font-weight: 400
            line-height: 100%
            letter-spacing: -0.8px
            transform: translateY(120%)
            display: inline-block
            transition: var(--trs-1200) 400ms
            @media(max-width: $tab)
                transform: translateY(0)
                opacity: 1