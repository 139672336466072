@import 'shared/styles/vars'
    
.section.active
    .content
        .top
            .info
                transform: translateY(0)
                opacity: 1
        .bottom
            .title
                .word
                    opacity: 1
                    transform: translateY(0)
    .swiperWrapper
        opacity: 1
        transform: scale(1)
        img
            transform: scale(1)
.section
    height: 100%
    background: var(--surface-quaternary-color)
    width: 100%
    display: grid
    grid-template-columns: 35% 60%
    align-items: center
    justify-content: space-between
    padding: 150px 48px 48px
    box-sizing: border-box
    @media(max-width: $tab)
        padding: 1.5rem 1rem
        display: flex
        flex-direction: column
        position: relative
    .content
        z-index: 2
        display: grid
        grid-template-rows: repeat(2,1fr)
        height: 100%
        @media(max-width: $tab)
            // width: 100%
            position: absolute
            left: 1rem
            right: 1rem
            top: 1rem
            bottom: 1rem
            box-sizing: border-box
        .top
            display: grid
            grid-template-columns: repeat(3,1fr)
            
            .info
                display: flex
                flex-direction: column
                align-items: flex-start
                overflow: hidden
                transform: translateY(-30%)
                opacity: 0
                transition: var(--trs-1200) 300ms
                @media(max-width: $tab)
                    opacity: 1
                    transform: translateY(0)
                .value
                    font-family: Cera Pro, sans-serif
                    font-size: 64px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    letter-spacing: -3.2px 
                    color: var(--text-secondary-color)
                .label
                    font-family: Cera Pro, sans-serif
                    font-size: 14px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    letter-spacing: -0.7px 
                    color: var(--text-secondary-color)
                    display: inline-block
                    
        .bottom
            display: flex
            align-items: flex-end
            border-top: 1px solid rgba(64, 32, 32, 0.20)
            position: relative
            @media(max-width: $tab)
                border-top: none
                position: absolute
                bottom: 2rem

            .title
                top: 48px
                position: absolute
                white-space: nowrap
                font-family: Cera Pro, sans-serif
                font-size: 96px
                font-style: normal
                font-weight: 400
                line-height: 100%
                letter-spacing: -2px 
                color: var(--text-secondary-color)
                text-transform: uppercase
                overflow: hidden
                .word
                    display: inline-block
                    opacity: 0
                    transition: var(--trs-1200) 300ms 
                    transform: translateY(50%)
                @media(max-width: $tab)
                    display: none
            .navigation
                display: flex
                gap: 8px
    .swiperWrapper
        display: flex
        align-items: center
        justify-content: center
        height: 100%
        position: relative
        padding:  0 24px 24px
        box-sizing: border-box
        opacity: 0
        transform: scale(.9)
        transition: var(--trs-1000) 300ms
        @media(max-width: $tab)
            width: 100%
            padding: 0
            padding-bottom: 1rem
            margin: 5rem 0
            opacity: 1
            transform: scale(1)
        .swiper
            width: 100%
            height: 100%
            position: relative
        img
            position: absolute
            height: 100%
            width: 100%
            object-fit: cover
            transform: scale(1.9)
            transition: var(--trs-1200) 300ms
            @media(max-width: $tab)
                transform: scale(1)
        .btn
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            margin: auto
            height: 55px
            width: 55px
            padding: 0