@import 'shared/styles/vars'
  
.photo
    background: var(--surface-quaternary-color)
    height: 100%
    display: grid
    grid-template-columns: 45% 45%
    align-items: flex-end
    align-content: center
    justify-content: space-evenly
    @media(max-width: $tab)
        padding: 0
        display: flex
        justify-content: center
    .leftPart
        height: 100%
        display: flex
        flex-direction: column
        justify-content: flex-end
        position: relative
        .swiperText
            display: flex
            align-items: center
            height: fit-content
            max-height: 428px
            width: 100%
            margin-bottom: 30px
            .swiperItem
                span
                    color: var(--text-secondary-color)
                    font-family: Cera Pro
                    font-size: 24px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    letter-spacing: -1.2px 
                    display: block
                    margin-bottom: 5px
                p
                    font-family: Cera Pro
                    font-size: 48px
                    font-style: normal
                    font-weight: 400
                    line-height: 100%
                    letter-spacing: -2.4px
                    color: var(--text-secondary-color)
                    margin: 0
        .swiper
            display: flex
            align-items: center
            height: 428px
            max-height: 428px
            width: 100%
            .swiperItem
                width: 100%
                height: 100%
                position: relative
                img
                    object-fit: cover
                    position: absolute
                    width: 100%
                    height: 100%
        @media(max-width: $tab)
            display: none
        .photoBtn
            position: absolute
            bottom: -80px
            width: 250px
            height: 54px
    .rightPart
        display: flex
        flex-direction: column
        gap: 4.8rem
        justify-content: center
        @media(max-width: $tab)
            gap: 4rem
            justify-content: center
            height: 100%
            width: 100%
            padding: 1rem
            box-sizing: border-box
        .menuList
            display: flex
            flex-direction: column
            gap: 1.5rem
            max-height: 50vh
            height: 100%
            overflow-y: auto
            scrollbar-width: thin
            scrollbar-color: var(--surface-tertiary-color) var(--surface-quaternary-color) 
            height: 100%
            &::-webkit-scrollbar
                width: 10px
  
            &::-webkit-scrollbar-thumb 
                background: var(--surface-tertiary-color)
                
            @media(max-width: $tab)
                padding-top: 6rem
                gap: 1rem
            
            span.active
                opacity: 1
            span
                cursor: pointer
                font-family: Cera Pro
                font-size: 64px
                font-style: normal
                font-weight: 400
                line-height: 70%
                letter-spacing: -4.16px
                text-transform: uppercase 
                color: var(--text-secondary-color)
                opacity: .2
                transition: var(--trs-500)
                &:hover
                    opacity: 1
                @media(max-width: $tab)
                    text-align: center
                    font-size: 36px
                    