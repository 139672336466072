@import 'shared/styles/vars'

    
.section.active
    .mainText
        .wordWrapper
            &:nth-child(1)
                .word
                    transition-delay: 800ms
            &:nth-child(2)
                .word
                    transition-delay: 1000ms
            &:nth-child(3)
                .word
                    transition-delay: 1200ms
            .word
                opacity: 1
                transform: translateY(0)
.section
    height: 100%
    overflow: hidden
    @media(max-width: $tab)
        background: var(--surface-secondary-color)
    .mainText
        display: flex
        flex-direction: column
        position: absolute
        justify-content: flex-start
        top: 8.5rem
        right: var(--container)
        width: 425px
        @media(max-width: 1455px)
            width: 325px
        @media(max-width: 1160px)
            width: 280px
        .wordWrapper
            height: fit-content
            overflow: hidden
            position: relative
            .word
                transform: translateY(100%)
                transition: all var(--trs-700)
                opacity: 0
                transform-origin: bottom
                display: inline-block
                font-family: Cera Pro
                font-size: 40px
                font-style: normal
                font-weight: 400
                line-height: 100% 
                letter-spacing: -2px
                color: var(--text-primary-color)
                @media(max-width: 1455px)
                    font-size: 36px
                @media(max-width: 1160px)
                    font-size: 28px
           
                    