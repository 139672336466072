@import 'shared/styles/vars'
  
.photo
    background: var(--surface-quaternary-color)
    align-items: center
    padding: 1.5rem 1rem
    display: flex
    .menuList
        display: flex
        flex-direction: column
        gap: 1.5rem
        width: 100%
        max-height: 70vh
        height: 100%
        overflow-y: auto
        scrollbar-width: thin
        scrollbar-color: var(--surface-tertiary-color) var(--surface-quaternary-color) 
        height: 100%
        padding-right: 8px
        &::-webkit-scrollbar
            width: 10px

        &::-webkit-scrollbar-thumb 
            background: var(--surface-tertiary-color)
        @media(max-width: $tab-sm) 
            max-height: 60vh

        span.active
            opacity: 1
        span
            text-align: left
            cursor: pointer
            font-family: Cera Pro
            font-size: 36px
            font-style: normal
            font-weight: 400
            line-height: 100%
            letter-spacing: -1.8px
            color: var(--text-secondary-color)
            opacity: .2
            transition: var(--trs-500)
            &:hover
                opacity: 1
        .date
            opacity: 1
            font-family: Cera Pro
            font-size: 14px
            font-style: normal
            font-weight: 400
            line-height: 100%
            letter-spacing: -0.7px
            margin-bottom: 14px 
            display: block
        .imageWrapper 
            aspect-ratio: 1.5
            img 
                width: 100%
                height: 100%
                object-fit: cover
        .photoBtn
            position: relative
            margin-top: 14px
            height: 54px
            span
                font-size: 16px
                font-style: normal
                font-weight: 400
                line-height: 140% 
                opacity: 1
                letter-spacing: 0
            @media(max-width: $tab-sm) 
                width: 100%