:root 
    --surface-primary-color: #402020
    --surface-secondary-color: #293630
    --surface-secondary-light-color: #557164
    --surface-tertiary-color: #AC7F66
    --text-primary-color:#DCC5B7
    --text-secondary-color:#402020
    --surface-quaternary-color:#DCC5B7
    --surface-quaternary-light-color:#eed9cc
    --white-color:#fff
    --black-color:#000
    --soft-gray-color:rgba(220, 197, 183, .2)

    --trs-300: 300ms ease
    --trs-500: 500ms ease
    --trs-700: 700ms ease
    --trs-1000: 1000ms ease
    --trs-1200: 1200ms ease

    --container: 3rem

    --rounded-xl: 3.3rem
    --rounded-full: 99999px


// sass переменные для медиа запросов
$tab: 1024px
$tab-sm: 767px