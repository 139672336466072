
body
   background: var(--surface-primary-color)     
    
.container
    padding: 0 3rem
    @media(max-width: $tab)
        padding: 0 1rem

.noScroll
    position: fixed
    overflow: hidden
    top: 0
    bottom: 0
    left: 0
    right: 0