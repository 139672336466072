@import 'shared/styles/vars'
    
.menu.open
    transform: translateY(0)
    opacity: 1
    pointer-events: all

.menu
    background: var(--surface-secondary-color)
    position: fixed
    opacity: 0
    pointer-events: none
    top: 0
    bottom: 0
    left: 0
    right: 0
    z-index: 10
    transform: translateY(100%)
    transition: transform var(--trs-500), opacity var(--trs-300)
    display: grid
    grid-template-columns: 45% 45%
    align-items: center
    align-content: center
    justify-content: space-evenly
    padding: 128px 48px 48px
    @media(max-width: $tab)
        padding: 0
        display: flex
        justify-content: center
    .leftPart
        height: 100%
        display: flex
        align-items: center
        .swiper
            display: flex
            align-items: center
            max-width: 480px
            height: 110%
            width: 80%
            .swiperItem
                width: 100%
                height: 100%
                position: relative
                img
                    object-fit: cover
                    position: absolute
                    width: 100%
                    height: 100%
        @media(max-width: $tab)
            display: none
        img

    .rightPart
        display: flex
        flex-direction: column
        gap: 4.8rem
        justify-content: center
        @media(max-width: $tab)
            gap: 4rem
            justify-content: center
            height: 100%
            width: 100%
            padding: 1rem
            box-sizing: border-box
        .menuList
            display: flex
            flex-direction: column
            gap: 1.5rem
            @media(max-width: $tab)
                padding-top: 6rem
                gap: 1rem
            span
                cursor: pointer
                font-family: Cera Pro
                font-size: 64px
                font-style: normal
                font-weight: 400
                line-height: 70%
                letter-spacing: -4.16px
                text-transform: uppercase 
                color: var(--text-primary-color)
                opacity: .2
                transition: var(--trs-500)
                &:hover
                    opacity: 1
                @media(max-width: $tab)
                    text-align: center
                    font-size: 36px
                    
                    
        .btns
            display: flex
            align-items: center
            gap: .5rem
            .btn
                display: flex
                justify-content: center
                height: 54px
                padding: 0
                width: 240px
            @media(max-width: $tab)
                flex-direction: column
                width: 100%
                .btn
                    width: 100%
                button
                    width: 100%
                    display: flex
                    justify-content: center
            .socialBtns
                display: flex
                align-items: center
                gap: .5rem
                a
                    svg
                        width: 22px
                        height: 22px
                @media(max-width: $tab)
                   

