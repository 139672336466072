.sectionThreeText.active
    .wordWrapper
        .word
            transition-delay: 600ms
            opacity: 1
            transform: translateY(0)
.sectionThreeText
    right: var(--container)
    position: absolute
    bottom: var(--container)
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
    max-width: 425px
    @media(max-width: 1455px)
        width: 325px
    @media(max-width: 1160px)
        width: 280px
    .wordWrapper
        overflow: hidden
        position: relative
        padding: 0 5px 0
        .word
            color: var(--text-primary-color)
            font-family: Cera Pro
            font-size: 18px
            font-style: normal
            font-weight: 400
            line-height: 100%
            letter-spacing: -0.8px
            transform: translateY(120%)
            display: inline-block
            transition: var(--trs-1200) 0ms
            // @media(max-width: 1455px)
            //     font-size: 14px
            @media(max-width: 1160px)
                font-size: 12px