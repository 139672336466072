@import 'shared/styles/vars'
  
.accordion.active
    .titleWrapper
        margin-bottom: 14px
        span
            opacity: 1
        h5
            color: var(--white-color)
        svg
            transform: rotate(360deg)
.accordion
    .content
        max-height: 0
        overflow: hidden
        transition: max-height 1000ms,  opacity 500ms
        opacity: 0
        padding-bottom: 5px
    .content.show
        transition: max-height 1000ms, opacity 500ms
        max-height: 100vh
        opacity: 1

