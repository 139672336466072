@import 'shared/styles/vars'
    
.sectionOneText.active
    .wordWrapper
        .word
            transform: translateY(0)
            transition-delay: 0ms
.sectionOneText
    left: var(--container)
    position: absolute
    bottom: 3.5rem
    max-width: 320px
    display: flex
    justify-content: flex-start
    flex-wrap: wrap
    @media(max-width: $tab)
        left: 1rem
        bottom: 1rem
    .wordWrapper
        overflow: hidden
        padding: 0 5px 0
        position: relative
        .word
            transform: translateY(120%)
            display: inline-block
            transition: var(--trs-700) 1500ms
            font-family: Cera Pro
            font-size: 16px
            font-style: normal
            font-weight: 400
            line-height: 100%
            color: var(--text-primary-color)
            @media(max-width: $tab)
                transform: translateY(0)